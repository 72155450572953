@import '../import';

#modifiers {
	min-height: 200px;
	@media (min-width: 576px) {
		height: 316px;
	}
}
#itemOptions {
	height: 100%;
	border-left: 1px solid $border-colour;
	.Checkbox label {
		white-space: nowrap;
	}
}
.PriceOptions label {
	width: 100px;
}