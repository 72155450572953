@import '../import';

.Modal {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
	border-radius: $pane-border-radius;
	background-color: #FFF;

	&_fullHeight {
		max-height: calc(100vh - 20px);
		@include media-breakpoint-up(md) {
			max-height: calc(100vh - 60px);
		}
		.Modal-footer {
			overflow: inherit;	// otherwise, the height setting squishes the footer
		}
	}

	@include media-breakpoint-up(md) {
		margin: 20px auto;
		width: 600px;
		&.Modal_small {
			width: 450px;
		}
		&.Modal_max {
			width: 100%;
			margin: auto;
		}
	}
	@include media-breakpoint-up(lg) {
		&.Modal_medium {
			width: 800px;
		}
		&.Modal_large {
			width: 992px;
		}
	}

	&_mask {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 10px;
		background-color: rgba(0, 0, 0, 0.5);
		visibility: hidden;
		opacity: 0;
		transition: $transition-slow;
		// z-index: 1050;	// same as Bootstrap's
		z-index: 1000;	// this MUSTN'T be greater than 1000 or Google Map's AutoComplete dropdown (used on VenueEditorDialog) can't be used (.pac-container has a z-index of 1000)
		overflow-y: auto;
	}

	&_show {
		visibility: visible;
		opacity: 1;
	}

	&-header {
		background-color: $primary-colour;
		border-radius: $pane-border-radius $pane-border-radius 0 0;
		text-transform: uppercase;
		color: #FFF;
		.react-draggable & {
			cursor: move;
		}

		h1 {
			font-size: inherit;
			font-weight: inherit;
			line-height: 20px;
			padding: 15px 20px;
			margin: 0;
			display: block;
			// border-radius: $pane-border-radius $pane-border-radius 0 0;
		}

		&_nav {
			padding: 0;
			@include media-breakpoint-down(xs) {
				height: auto;
				.nav {
					flex-direction: column;
				}
			}
		}
		.nav {
			.nav-item {
				position: relative;
				line-height: 50px;
				cursor: default;
				border-radius: 0;
				// transition: background-color .15s linear;
				transition: $transition;

				&:first-child {
					border-radius: $pane-border-radius 0 0 0;
				}

				&:last-child {
					border-radius: 0 $pane-border-radius 0 0;
				}

				&:not([disabled]).active,
				&:not([disabled]):hover,
				&:not([disabled]):focus {
					cursor: pointer;
					background-color: darken($primary-colour, 10%);
					outline: 0;
				}

				&[disabled] {
					opacity: 0.5;
				}

				@include media-breakpoint-up(sm) {
					&.active:after {
						top: 100%;
						left: 50%;
						border: solid transparent;
						content: "";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-top-color: darken($primary-colour, 10%);
						border-width: 8px;
						margin-left: -8px;
					}
				}
			}
		}
	}

	&-body {
		flex: 1;
		width: 100%;
		padding: 20px;
		.form-row, .row {
			align-items: center;
			> label {
				padding-right: 5px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.simple-controls {
			// when you just want a single row of a few controls
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		&.simple-form {
			// for a single column of full-width controls
			.form-control + .form-control {
				margin-top: 1rem;
			}
		}
		&_padding0 {	// what's this for??
			padding: 0;
		}
	}

	&-footer {
		display: flex;
		justify-content: flex-end;
		overflow: hidden;	// hmm, why is this here???
		height: 50px;
		padding: 0 20px 15px;
		.btn {
			min-width: 100px;
			&:first-of-type {
				margin-left: auto;
			}
		}
		.btn + .btn {
			margin-left: 10px;
		}
		&-message {
			align-self: center;
		}
	}

	&.Pane {
		// in case combine a Modal with Pane styling (as used on menus options dialog for footer)
		border: none;
	}
}