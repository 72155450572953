@import '../_import';

.ImageControl {
	font-weight: 400;
	display: block;
	cursor: pointer;
	outline: 0;
	user-select: none;

	&[disabled] {
		opacity: $disabled-opacity;
	}

	input {
		display: none;
	}

	label {
		display: inline-block;
		line-height: 30px;
		white-space: nowrap;
		cursor: pointer;
		margin-bottom: 0.5rem;

		&:before {
			content: ".";
			display: inline-block;
			color: transparent;
			width: 30px;
			height: 30px;
			margin-right: 10px;
			background-image: url(/assets/img/item-settings.svg);
			background-size: 30px;
			// transition: background-position .15s linear;
			transition: $transition;
		}

	}

	&:last-child label {
		margin-bottom: 0;
	}

}

.control-dairyFree label:before {
	background-position: 0 0;
}
.control-dairyFree.control-1 label:before {
	background-position: 0 -30px;
}
.control-glutenFree label:before {
	background-position: 0 -60px;
}
.control-glutenFree.control-1 label:before {
	background-position: 0 -90px;
}
.control-nutSafe label:before {
	background-position: 0 -120px;
}
.control-nutSafe.control-1 label:before {
	background-position: 0 -150px;
}
.control-veggieStatus label:before {
	background-position: 0 -180px;
}
.control-veggieStatus.control-a label:before {
	background-position: 0 -210px;
}
.control-veggieStatus.control-b label:before {
	background-position: 0 -240px;
}

.control-spicyStatus label:before {
	background-position: 0 -270px;
}
.control-spicyStatus.control-1 label:before {
	background-position: 0 -300px;
}
.control-spicyStatus.control-2 label:before {
	background-position: 0 -330px;
}
.control-spicyStatus.control-3 label:before {
	background-position: 0 -360px;
}