@import '../import';

// Note: colours marked @@ NOTE @@ in this file need to match the fill colours in the three tear*.svg files

$tear-size: 5;
$grouping-colour: $secondary-colour;
$normal-colour: #E8E8E8;
$rush-colour: lighten($primary-colour, 30%);
$priority-colour: lighten($warning-colour, 30%);

.orders-container {
	border: 1px solid #ccc;
	border-radius: $pane-border-radius;
	background-color: #FFF;
	height: 800px;
	columns: 3;
	grid-column-gap: 1rem;
	column-gap: 1rem;
	padding: 0 1rem;		// the 0 and the commented out first-child stuff in .order removes the top and bottom gaps for "wrapping" tickets
	overflow: hidden;

	.fullscreen & {
		border-width: 0;
	}

	.order {
		&-container {
			background-color: transparent;
			margin-top: 1rem;
			// &:first-child {
			// 	margin-top: 0;
			// }
			// &:first-child:not(.order-grouped) {
			// 	padding-top: 0;
			// }
		}

		&-grouped {
			&_first .order {
				padding-bottom: 1.5em;
			}
			&_middle {
				margin-top: #{ -$tear-size }px;
				& .order {
					padding-bottom: 1.5em;
				}
			}
			&_last {
				margin-top: #{ -$tear-size }px;
			}
		}

		background-color: $normal-colour;
		padding: .5em;
		overflow: hidden;

		&_priority {
			background-color: $priority-colour;
		}

		&_rush {
			background-color: $rush-colour;

			.order_lapsed {
				color: $error-colour;
			}
		}

		&-line {
			color: transparent;
		}

		&-header {
			display: flex;
			gap: 1em;

			> div:last-child {
				text-align: right;
				margin-left: auto;
			}
		}

		&-comments {
			border: 1px solid darken($comments-colour, 30%);
			border-radius: $control-border-radius;
			background-color: $comments-colour;
			padding: 0 .25rem;
		}

		&-body {
			background-color: #FFF;
			margin-top: .5rem;
		}

		&-item {
			display: flex;
			&:not(:last-child) {
				border-bottom: 1px solid $light-border-colour;
			}
			> div {
				padding: .125em .35em;
				&:first-child {
					text-align: right;
					flex: 0 0 2.5em;
					border-right: 1px solid $light-border-colour;
				}
			}
		}

		&-footer {
			text-align: center;
			padding-top: .75em;
		}

		&-tear {
			height: #{ $tear-size }px;
			&:first-child {
				background-image: url(/assets/img/tear-top.svg);
			}
			&:last-child{
				background-image: url(/assets/img/tear.svg);
			}
			background-repeat: repeat-x;
			background-size: #{ $tear-size * 2 }px #{ $tear-size }px;
			&_priority {
				&:first-child {
					background-image: url(/assets/img/tear-top-priority.svg);
				}
				&:last-child{
					background-image: url(/assets/img/tear-priority.svg);
				}
			}
			&_rush {
				&:first-child {
					background-image: url(/assets/img/tear-top-rush.svg);
				}
				&:last-child{
					background-image: url(/assets/img/tear-rush.svg);
				}
			}
		}

		&-number {
			div:first-child {
				font-size: 2em;
				line-height: 1;
				text-align: right;
			}
		}
	}
}

.Orders-footer {
	height: 70px !important;
	padding-bottom: 10px;
	.IconButtonGroup-value {
		width: 44px;
		padding: 7px 0;
		text-align: center;
	}
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	label {
		display: block;
	}
	.fullscreen & {
		height: 50px !important;
		label {
			display: none;
		}
	}
	.page-info {
		background-color: $main-background-colour;
	}
}

.control-priority .IconButtonGroup-value {
	background-color: $priority-colour;
}

.control-rush .IconButtonGroup-value {
	background-color: $rush-colour;
}