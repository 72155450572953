@import 'import';

#menusPane {
	min-height: 200px;
	.Collapsible header button {
		@include item-button;
	}
	.PaneMenu .Pane-items {
		max-height: 150px;	// stops the pane menu getting clipped on mobile
	}
	@media (min-width: 576px) {
		height: 715px;
		.with-sales-tax & {
			height: 765px;
			height: 759px;
		}
		.PaneMenu .Pane-items {
			max-height: 713px;	// stops the pane menu getting clipped
		}
	}
	.Pane-body {
		background-color: $toolbar-colour;
	}
}

#menuItemPane {
	.Uploader {
		min-height: 200px;
	}
	.Pane-body > .form-row {
		> div:first-child {
			margin-bottom: 10px;
			@media (min-width: 576px) {
				margin-bottom: 0;
			}
		}
	}
	#customerSatisfaction {
		align-items: center;
		label {
			padding-left: 10px;
			&[disabled] {
				opacity: $disabled-opacity;
			}
		}
	}
}
