@import '../import';

.pageHeader {
	background-color: #FFF;

	&-control {
		display: flex;
		align-items: center;
		text-align: right;
		margin-left: auto;
		cursor: pointer;
		h1, h2 {
			margin: 0 .75rem;
		}
		h1 {
			color: $primary-colour;
			font-size: 14px;
			text-transform: uppercase;
		}
		h2 {
			font-size: 13px;
		}
		&:hover {
			.material-icons {
				opacity: .8;
			}
		}
	}

	&_centred {
		// as used on 404 page
		display: flex;
		align-items: center;
		justify-content: center;
		height: 52px;
		h1 {
			margin: 0;
		}
	}
}

.VenueHeader {
	display: flex;
	height: 52px;
	// border-bottom: 1px solid $light-border-colour;
	.collapsed &,
	.fullscreen & {
		display: none;
	}
	#venueInfo {
		text-align: left;
		padding-left: 10px;
		margin-left: 0;
		min-width: 50%;
		button {
			order: -1;
		}
	}
	.LanguagePicker {
		border-left: 1px solid $light-border-colour;
		@include venue-header-control;
		margin-left: auto;
		> button {
			width: 80px;
			height: 52px;
		}
		span > .material-icons {
			@include material-icon(24px);
			color: $control-colour;
		}
	}
	button {
		margin: 0;
		&:hover .material-icons,
		&:focus .material-icons {
			opacity: .8;
		}
	}
}