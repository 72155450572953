@import '../_import';

.Checkbox {
	&[disabled] {
		opacity: $disabled-opacity;
	}
	&-label {
		position: relative;
		padding: 0 0 0 25px;
		cursor: default;
		.labelFirst & {
			padding: 0 25px 0 0;
		}
	}
	&-text {
		display: inline-block;
	}
	&-input + &-text:before {
		content: "";
		color: #FFF;
		background-color: #F8F8F8;
		left: 0;
		top: 0;
		width: 18px;
		height: 18px;
		@include material-icon-style;
		font-weight: 700;
		font-size: 14px;
		border: 2px solid $border-colour;
		border-top-left-radius: 10px 5px;
		border-top-right-radius: 10px 5px;
		border-bottom-left-radius: 10px 5px;
		border-bottom-right-radius: 10px 5px;
		transition: $transition;
		.labelFirst & {
			left: auto;
			right: 0;
		}
	}
	&-input:checked + &-text:before {
		content: "check";
		background-color: $border-colour;
	}
	&-input:checked:not([readonly]) + &-text:before {
		background-color: $primary-colour;
		border-color: $primary-colour;
	}
	&-input:focus:not([readonly]) + &-text:before {
		border: 2px solid $primary-colour;
		color: #FFF;
		@include focus-shadow;
	}
}

.Radio {
	&[disabled] {
		opacity: $disabled-opacity;
	}
	&-label {
		position: relative;
		padding: 0 0 0 25px;
		min-height: 21px;
		cursor: default;
		.labelFirst & {
			padding: 0 25px 0 0;
		}
	}
	&-text {
		display: inline-block;
	}

	&-input + &-text:before {
		content: "";
		position: absolute;
		display: inline-block;
		left: 2px;
		top: 2px;
		border: 1px solid #999;
		border-radius: 8px;
		color: #FFF;
		background-color: #FFF;
		text-align: center;
		width: 14px;
		height: 14px;
		.labelFirst & {
			left: auto;
			right: 2px;
		}
	}

	&-input + &-text:after {
		content: "";
		position: absolute;
		background-color: $primary-colour;
		border-radius: 4px;
		left: 9px;
		top: 9px;
		width: 0;
		height: 0;
		transition: $transition;
		.labelFirst & {
			left: auto;
			right: 9px;
		}
	}

	&-input:checked + &-text:after {
		left: 5px;
		top: 5px;
		width: 8px;
		height: 8px;
		.labelFirst & {
			left: auto;
			right: 5px;
		}
	}

	&-input:focus + &-text:after {
		@include focus-shadow;
	}
}

.Checkbox + .Checkbox, .Radio + .Radio {
	margin-top: 5px;
}

.Checkbox.inline, .Radio.inline {
	display: inline-block;
}
.Checkbox.inline + .inline, .Radio.inline + .inline {
	margin-top: 0;
	margin-left: 20px;
}

.InputWithButton {
	.btn:disabled {
		opacity: $disabled-opacity;
	}
	&.validation-fail {
		> .form-control,
		> .input-group-append > .btn {
			border-color: $primary-colour!important;
		}
	}
}

.search-container {
	position: relative;
	&::after {
		color: $control-colour;
		opacity: 0.75;
		content: "search";
		right: 5px;
		top: 5px;
		@include material-icon-style;
		transition: $transition;
	}
	&:hover:after {
		opacity: 1;
	}
}

.Slider {
	height: 22px;
	/* overrides for various browsers */
	input[type=range] {
		-webkit-appearance: none;
		width: 100%;
		padding: 0;
	}
	input[type=range]:focus {
		outline: none;
	}
	input[type=range]::-webkit-slider-thumb {
		border-width: 0;
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: $primary-colour;
		cursor: pointer;
		-webkit-appearance: none;
	}
	input[type=range]::-moz-range-thumb {
		border-width: 0;
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: $primary-colour;
		cursor: pointer;
	}
	input[type=range]::-ms-thumb {
		border-width: 0;
		height: 18px;
		width: 18px;
		border-radius: 10px;
		background: $primary-colour;
		cursor: pointer;
	}
	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 11px;
		cursor: pointer;
		background: #FFF;
		border-bottom: 2px solid $border-colour;
	}
	input[type=range]::-moz-range-track {
		width: 100%;
		height: 2px;
		cursor: pointer;
		background: $border-colour;
	}
	input[type=range]::-ms-track {
		width: 100%;
		height: 2px;
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		border-width: 10px 0;
		color: transparent;
	}
	input[type=range]::-ms-fill-lower {
		background: #999;
		border: 0.2px solid $border-colour;
	}
	input[type=range]::-ms-fill-upper {
		background: $border-colour;
		border: 0.2px solid $border-colour;
	}
}