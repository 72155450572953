@import '../import';

.OrdersList-filters {
	margin-bottom: 20px;
	.fullscreen & {
		display: none;
	}
	.col-form-label {
		padding-top: 0;
	}
}

.OrdersList-pane {
	background-color: #F6F6F6;
	padding-bottom: $pane-border-radius;
	.fullscreen & {
		margin: 10px;
	}
	.Pane-header {
		display: flex;
		padding: 15px 100px 15px 32px;
	}
	div[class*="col"] {
		// set all the spacing for all columns to keep them the same
		padding-right: 5px;
		padding-left: 5px;
	}
	.Pane-item_textButton {	// BUMP button
		right: 45px;
	}
	.Collapsible {
		header {
			padding-right: 100px;	// needs to match .Pane-header above so items line up with pane headings
		}
		small {
			color: lighten($text-colour, 15%);
		}
		.Order_detail {
			display: none;
		}
		&.open {
			margin-bottom: 13px;
			.Order_detail {
				white-space: nowrap;
				display: block;
			}
		}
		&:not(.open) header > div {
		// &.KitchenList header > div {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.order-grouped {
		&_first,
		&_middle {
			header {
				border-bottom-color: transparent;
			}
		}
	}

	&.KitchenList {
		.Order_detail {
			display: none !important;
		}
		header > div {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.Order-item {
		position: relative;
		display: flex;
		background-color: #FFF;
		padding: 10px 16px;
		margin-left: 13px;
		border-left: 3px solid $divider-colour;
		border-bottom: 1px solid $divider-colour;
		&.description {
			padding-right: 100px;
			> div {
				// lines up columns when $ is visible
				padding-right: 5px;
				padding-left: 5px;
			}
			> div:first-child {
				flex: 0 0 57.14%;	// = (100 / 7) * 4
				max-width: 57.14%;
			}
		}
		&:last-child {
			border-bottom-width: 1px;
		}
		&:hover {
			color: inherit;
		}
		&_comment {
			border-bottom-width: 0 !important;
			padding-top: 5px;
			padding-right: 100px;
			padding-bottom: 0;
			.comment {
				position: relative;
				display: inline-block;
				max-width: 50%;
				margin-left: 14.3%;	// should keep it lined up with the patron's name
				padding: 5px 10px;
				border: 1px solid darken($comments-colour, 30%);
				border-radius: $control-border-radius;
				background-color: $comments-colour;
				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 20px;
					bottom: 100%;
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-bottom-color: darken($comments-colour, 30%);
				}
				&:after {
					content: '';
					display: block;
					position: absolute;
					left: 21px;
					bottom: 100%;
					width: 0;
					height: 0;
					border: 9px solid transparent;
					border-bottom-color: $comments-colour;
				}
			}
		}
		&_withModifiers {
			border-bottom-width: 0 !important;
			padding-bottom: 0;
		}
		.modifier {
			padding: 5px 10px;
			border: 1px solid $border-colour;
			border-radius: $control-border-radius;
			& + .modifier {
				margin-left: 0.5rem;
			}
		}
	}
}

#orderDetailsDialog {
	.Modal-body > .row {
		align-items: flex-start;
	}
}

#orderModifyDialog {
	.Pane {
		max-height: 400px;
		background-color: $main-background-colour;
	}
	li:last-child .Pane-item {
		border-radius: 0 0 $pane-border-radius $pane-border-radius;
	}
	.Pane-item {
		background-color: #FFF;
		margin-left: 0;
		border-left: 4px solid transparent;

		&:last-child {
			margin-bottom: $pane-border-radius;
		}

		&.deleteMe:after {
			content: "remove_circle_outline";
			color: $primary-colour;
			top: 7px;
			right: 5px;
			@include material-icon-style;
		}

		&.addMe:after {
			content: "add_circle_outline";
			color: $border-colour;
			color: $primary-colour;
			top: 7px;
			right: 5px;
			@include material-icon-style;
		}

		&:hover {
			border-left-color: $primary-colour;
		}

		&.modifier {
			margin-left: 10px;
			&.locked {
				opacity: 0.65;
				&:hover {
					color: inherit;
					border-color: transparent;
					cursor: default;
				}
			}
		}
	}

	textarea {
		height: 54px;
	}

	.Checkbox {
		margin-bottom: 5px;
	}

	.hint {
		margin-bottom: 0;
		font-style: italic;
		font-size: 10px;
	}
}

#orderItemAddDialog {
	min-height: 200px;
	.Pane {
		max-height: 371px;
		margin-bottom: 0;
	}
	.Pane-item_oos {	// out of stock
		text-decoration: line-through;
	}
}

#orderItemModifiersDialog {
	h2 {
		display: flex;
		font-size: inherit;
		background-color: $toolbar-colour;
		// margin: -1rem -1rem 1rem -1rem;
		padding: 0.75rem;
		margin: 0;
		border-top: 1px solid $border-colour;
		border-bottom: 1px solid $border-colour;
		div:first-child {
			font-weight: 700;
			margin-right: auto;
		}
	}
	.ValidationMessage {
		border-radius: 0;
		margin-bottom: 0;
	}
	.validation-fail {
		color: $primary-colour;
	}
}

.fixed-footer {	// compensate for orders pages where there is a 50px footer fixed to the bottom of the page
	padding-bottom: 50px;
}