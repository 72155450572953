@import '../_import';

.UploaderViewer {
	width: 100%;
	height: 200px;
	border: 1px solid $border-colour;
	border-radius: $control-border-radius;
	background-color: $toolbar-colour;
	background-image: url(/assets/img/dish.svg);
	background-position: center center;
	background-repeat: no-repeat;
	transition: $transition;

	@include media-breakpoint-up(sm) {
		height: 100%;
	}

	&_disabled {
		color: rgba($text-colour, 0.5);
	}

	.carousel {
		height: 100%;
		display: grid;
		&__slider {
			border-radius: $control-border-radius;
		}
	
		&__slider-tray-wrap--horizontal,
		&__slider-tray--horizontal,
		&__slide {
			height: 100%;
		}
	
		&__inner-slide {
			> div {
				width: 100%;
				height: 100%;
				// background-size: 100% auto;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}

		.btn {
			right: 0;
			top: auto;
			bottom: 0;
			transition: $transition;
	
			&.btn-back {
				left: 0;
				right: auto;
			}
	
			&:hover:not([disabled]),
			&:focus {
				opacity: 1;
			}
			&#btnHelp {
				top: 0;
				bottom: auto;
				.material-icons {
					opacity: .4;
					&:not([disabled]) {
						opacity: .5;
					}
				}
			}
		}
	
		label {
			position: absolute;
			width: calc(100% - 48px);
			margin-left: 24px;
			text-align: center;
			bottom: 0;
			height: 24px;
			line-height: 24px;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&_dragover {
		// background-color: #FFF;
		border-color: $primary-colour;
		@include focus-shadow;
	}

}