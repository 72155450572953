@import '../import';

.VenueStatusControl {
	@include venue-header-control;
	width: fit-content;
	margin-left: auto;
	&-feedback {
		.notification {
			// white-space: nowrap;	// maybe better to set a min-width? (if some translations end up v long)
		}
	}

	&_loading {
		@include notification;
	}

	> button {
		display: flex;
		text-align: right;
		height: 50px;
		padding: 0 30px 0 15px;
	}
	.led_off {
		width: 24px;
		#stop0 {
			stop-color: #CCC;
		}
		#stop1 {
			stop-color: #AAA;
		}
	}
	.led_red {
		width: 24px;
		#stop0 {
			stop-color: #E74C34;
		}
		#stop1 {
			stop-color: #FF0000;
		}
	}
	.led_green {
		width: 24px;
		#stop0 {
			stop-color: #00BB00;
		}
		#stop1 {
			stop-color: #80EE80;
		}
	}
}