// modal dialog with tab-style selections in header

.AddEditDialog {

	header {

		padding: 0;
		h1 {
			padding: 17px 20px;
			font-size: inherit;
			margin: 0;
		}

	}
	.Modal-body {
		small {
			color: #BBB;
		}
		.tab-pane.simple-controls {
			.form-control {
				margin-bottom: 10px;
				&:last-child {
					margin: 0;
				}
			}
			.Typeahead {
				margin-bottom: 10px;
				.form-control {
					margin: 0;
				}
			}
			.InputWithButton .form-control {
				margin-bottom: 0;
			}
		}
	}
}