@import '_import';

// having issues with gap not being properly supported by Safari pre v14.1
// marked with *1* - could update to revert to using gap once this is no longer a problem

$heading-colour: #333;

@mixin nav-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin section-margin-top {
	margin-top: 2rem;
	@include media-breakpoint-up(md) {
		margin-top: 4.3rem;
	}
}

.Home {
	margin-top: 100px;
	@include media-breakpoint-up(md) {
		margin-top: 140px;
	}
	padding: 0;
	&-header {
		@include nav-bar;
		border-bottom: 1px solid $light-border-colour;
		padding: 0 0.375rem;
		@include media-breakpoint-up(md) {
			padding: 0 2rem;
		}
		background-color: #FFF;
		
		&_toolbar {
			display: flex;
			// gap: 1rem;	*1*
		}
		.btn {
			margin: .25rem 0;
			&:not(:last-child) {
				margin-right: 0.375rem;	// *1*
				@include media-breakpoint-up(md) {
					margin-right: 1rem;
				}
			}
			&-secondary {
				background-color: #FFF;
				color: $primary-colour;
				font-weight: 700;
				&:not([disabled]):focus,
				&:not([disabled]):hover,
				&:not([disabled]):active {
					background-color: $primary-colour !important;
				}
			}
			@include media-breakpoint-down(md) {
				padding: 0.375rem;
				font-size: 12px;
			}
		}
	}
	&-logo {
		outline: 0;
		margin-right: 1rem;
		svg {
			width: 140px;
			height: 56px;
			width: 120px;
			height: 48px;
			@include media-breakpoint-up(md) {
				width: 200px;
				height: 80px;
			}
		}
		fill: $primary-colour;
		transition: $transition;
		&:focus,
		&:active,
		&:hover {
			fill: darken($primary-colour, 10%);
		}
	}
	h1 {
		color: $heading-colour;
		text-align: center;
		font-size: 1.7rem;
		@include media-breakpoint-up(md) {
			font-size: 2.1rem;
		}
		font-weight: 700;
		// font-family: Arial Black;
	}
	h2 {
		color: $heading-colour;
		text-align: center;
		font-size: 15px;
		@include media-breakpoint-up(md) {
			font-size: 20px;
		}
		font-weight: 400;
	}
	h3 {
		color: $heading-colour;
		font-size: 16px;
		@include media-breakpoint-up(md) {
			font-size: 20px;
		}
		font-weight: 700;
	}

	&-banner {
		@include section-margin-top();
	}

	&-content {
		@include section-margin-top();
		.row:first-child {
			@include media-breakpoint-up(md) {
				margin-bottom: 1rem;
			}
		}
	}

	&-images {
		margin-top: 1rem;
		@include media-breakpoint-up(md) {
			margin-top: 3rem;
		}
		border-top: 1px solid $divider-colour;
		h3 {
			margin: 0;
			text-align: center;
			margin-top: 2rem;
		}
		img {
			width: 100%;
			margin-top: 20px;
			@include media-breakpoint-up(md) {
				margin-top: 30px;
			}
			border: 1px solid $border-colour;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);
		}
	}

	&-info {
		display: flex;
		// gap: 2.5rem; *1*
	}
	&-img {
		width: 80px;
		flex: 0 0 80px;
		margin-right: 1.5rem;	// *1*
		@include media-breakpoint-up(md) {
			margin-right: 2.5rem;	// *1*
		}

		> img {
			width: 100%;
		}
	}
	&-txt {
		font-size: 16px;
		flex: 0 1 auto;
		p {
			font-family: Arial, sans-serif;
			opacity: .7;
		}
	}

	&-demo {
		margin-top: 4rem;
		padding: 3rem 0;
		background-color: #F6F6F6;
		border-top: 1px solid $divider-colour;
		& &_panel {	// double & to overrule BS4
			@include media-breakpoint-up(lg) {
				padding-left: 100px;
				padding-right: 100px;
				&:first-child {
					border-right: 1px solid $divider-colour;
				}
			}
		}
	}
	// &-demo &-info {
	// 	gap: 1rem;	*1*
	// }
	&-bullet {
		// font-size: 40px;
		flex: 0 0 34px;
		display: inline-block;
		width: 34px;
		height: 34px;
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		text-align: center;
		border: 2px solid #555;
		border-radius: 17px;
		margin-right: 1rem;	// *1*
	}

	#form {
		h3 {
			margin-bottom: 2rem;
		}
	}
	.form-row {
		margin-bottom: 1rem;
	}

	&-footer {
		font-size: 14px;
		color: #AAA;
		background-color: #333;
		padding: .5rem 0;
		.container {
			flex-direction: column;
			// gap: .5rem;	*1*
			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
			@include nav-bar;
			div {
				&:first-child {
					white-space: nowrap;
				}
				&:last-of-type {
					margin: 0 .5rem;
				}
			}
		}
		a,
		.btn-link {
			white-space: nowrap;
			color: #AAA;
			background-color: transparent;
			&:hover, &:focus {
				color: $primary-colour;
			}
		}
	}
}

.info {
	h2 {
		font-size: 1rem;
	}
	&-terms {
		ol ol {
			list-style-type: upper-alpha;
			ol {
				list-style-type: lower-roman;
			}
		}
	}
	&-prv {
		ol {
			list-style-type: upper-alpha;
		}
	}
	ol, ul {
		padding-inline-start: 1rem;
		margin: 0;
		li {
			margin: .25rem 0;
			button {	// this won't work if ever we put a link in that is not in a list - but if we change it needs to work in both the Home Modal and Ext.js
				background-color: transparent;
				padding: 0;
				font-family: inherit;
				color: $primary-colour;
				border: none;
				&:hover,
				&:focus,
				&:active {
					text-decoration: underline;
				}
			}
		}
	}
}

main.info {
	padding: 1rem;
	margin: 0;
	ol, ul {
		padding-inline-start: 1rem;
		margin: 0;
	}
}