@import '../import';

#modifiersPane {
	min-height: 201px;
	@include media-breakpoint-up(lg) {
		height: 588px;
	}
}

#usedInGroups {
	min-height: 152px;
	@include media-breakpoint-up(lg) {
		height: 373px;
	}
}
// note the different breakpoints due to modifiers (above) having columns
#modifierGroupsPane {
	min-height: 201px;
	height: auto;
	@include media-breakpoint-up(md) {
		height: 588px;
	}
}

#containsModifierItems {
	min-height: 202px;
	@include media-breakpoint-up(md) {
		height: 398px;
	}
}