@import '../_import';

.Typeahead {
	> .form-control {
		border-top-right-radius: $control-border-radius !important;
		border-bottom-right-radius: $control-border-radius !important;
	}

	> .btn {
		// we don't want to see this button but it needs to be in the dom for the dropdown to be positioned properly
		padding: 0 !important;
		height: 33px;
		margin-top: -33px;
		visibility: hidden;
	}

	.dropdown-menu {
		// top: 35px !important;
		width: 100%;
		font-size: inherit;
		padding: 5px 0;

		> li button {
			width: 100%;
			background-color: transparent;
			border: none;
		}

		> li a, li button {
			@include row;
		}

		> li:last-child {
			border-bottom: 0;
		}

		mark {
			color: $primary-colour;
			padding: 0;
			background-color: transparent;
		}
	}
}