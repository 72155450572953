.PriceOptions {
	label {
		text-align: left;
	}
	.Checkbox,
	.Radio {
		margin: 0!important;
		padding: 0!important;
	}
	.form-control {
		height: auto;
	}
	.input-group-text {
		color: #999;
	}
	&_stacked {
		.input-group:first-child {
			.input-group-text {
				border-bottom-left-radius: 0;
			}
			.form-control_money {
				border-bottom-right-radius: 0;
			}
		}
		.input-group:last-child {
			margin-top: -1px;
			.input-group-text {
				border-top-left-radius: 0;
			}
			.form-control_money {
				border-top-right-radius: 0;
			}
		}
	}
}
// @media (min-width: 576px) {
// 	.PriceOptions.which-inline {
// 		> div:first-child {
// 			padding-left: 5px!important;
// 			padding-right: 0px!important;
// 			input[type=number] {
// 				border-radius: 0;
// 			}
// 		}
// 		> div:last-child {
// 			padding-left: 0px!important;
// 			.input-group-text {
// 				border-radius: 0;
// 				border-left-width: 0;
// 			}
// 		}
// 	}
// }
// .PriceOptions.which-stacked {
// 	> div > div:first-child {
// 		.input-group-text {
// 			border-bottom-left-radius: 0;
// 		}
// 		input[type=number] {
// 			border-bottom-right-radius: 0;
// 		}
// 	}
// 	> div > div:last-child {
// 		margin-top: -1px;
// 		.input-group-text {
// 			border-top-left-radius: 0;
// 		}
// 		input[type=number] {
// 			border-top-right-radius: 0;
// 		}
// 	}
// }